import { CartPage } from '@modules/cart'
import { getCookie } from 'cookies-next' // https://www.npmjs.com/package/cookies-next
import { decode, JwtPayload } from 'jsonwebtoken'
import { NextApiRequest, NextApiResponse } from 'next'
import {
  createHeaders,
  passSetCookie,
  whoAmI,
} from './api/spoonflower/helpers/alpenrose.service'
import { SPOONFLOWER_CONFIG } from './api/spoonflower/helpers/config'

const isValidToken = async (req: NextApiRequest, res: NextApiResponse) => {
  const token = getCookie(SPOONFLOWER_CONFIG.CT_COOKIE, { req, res })
  // check the token to see if it is expired or close to expiration
  // if it is, refresh it

  if (typeof token !== 'string') return false

  const decodedToken = decode(token) as JwtPayload | null

  if (!decodedToken) return false

  const validToken = (decodedToken?.exp ?? 0) * 1000 > Date.now() + 15000
  if (validToken) return true

  try {
    /**
     * Refresh the token
     */
    const headers = createHeaders(req, res)
    const response = await whoAmI(headers)
    passSetCookie(response, req, res)
    return true
  } catch (error) {
    return false
  }
}

export async function getServerSideProps({
  req,
  res,
}: {
  req: NextApiRequest
  res: NextApiResponse
}) {
  return {
    props: {
      hasUserToken: await isValidToken(req, res),
    },
  }
}

export default CartPage
